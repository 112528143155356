import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

// Hooks
import { useAuth, useInvalidate } from "@hooks/";

// Constants
import { KEY_FETCH_USER, KEY_USERS_GET_ALL } from "@constants/";

// Requests
import { fetchUser, getAllUsers, deleteUser as deleteUserApi, getOneUser } from "@api/";

// Interfaces
import { IUser } from "@interfaces/";

// Utils
import { error, success } from "@utils/";

// Enums
import { EFilters } from "@enums/";

// Types
import type { TSearch } from "@_types/";

export const initialUser: IUser = {
  id: 0,
  name: "",
  role_id: "",
  email: "",
};

export const useGetUser = () => {
  const { isAuthenticated, id } = useAuth();
  return useQuery({
    queryKey: [KEY_FETCH_USER, id],
    queryFn: async () => await fetchUser(id!),
    enabled: isAuthenticated && !!id,
    initialData: initialUser,
  });
};

export const useGetAllUsers = ({ search, page, per_page }: TSearch) => {
  const userPayload = useMemo(() => {
    return {
      filters: {
        [EFilters.SEARCH]: search,
        [EFilters.PAGE]: page,
        [EFilters.PER_PAGE]: per_page,
      },
    };
  }, [page, per_page, search]);

  const {
    data: users,
    isLoading: isUserLoading,
    isFetching,
  } = useQuery({
    queryKey: [KEY_USERS_GET_ALL, userPayload], // Ovo nam je kljuc preko koga kasnije mozemo da cachiramo
    queryFn: () => getAllUsers(userPayload), // ovo nam je funkcija koja vraca neki response
    initialData: [], // Ovde ide bilo koji data koji tebi odgovara da bude inicijalni
    enabled: true,
  });

  return { users, isLoading: isUserLoading || isFetching };
};

export const useDeleteUser = () => {
  const { invalidate } = useInvalidate();

  const deleteUser = async (data: { id: string }) => {
    try {
      const response = await deleteUserApi(data?.id);
      if (response) {
        success({ message: response?.data?.message });
        invalidate(KEY_USERS_GET_ALL);
      }
    } catch (err) {
      error({ title: "Something went wrong" });
    }
  };

  return { deleteUser };
};

export const useGetOneUser = ({ id }: { id: string | undefined }) => {
  const {
    data: user,
    isLoading: isUserLoading,
    isFetching,
  } = useQuery({
    queryKey: [KEY_USERS_GET_ALL, id],
    queryFn: () => getOneUser(id!),
    initialData: [],
    enabled: !!id,
  });

  return { user, isLoading: isUserLoading || isFetching };
};

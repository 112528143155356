// MARK: Imports
import { TextInput } from "@mantine/core";
import { useState, useMemo, useCallback, useEffect } from "react";
import { useDebouncedState } from "@mantine/hooks";

// Components
import { CustomTable, Filters, PageHeader, TableSkeleton } from "@components/";

// Requests
import { useDeleteUser, useGetAllUsers } from "@api/";

// Constants
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DEBOUNCE_TIME,
  DEFAULT_PER_PAGE,
  DEFAULT_TOTAL,
  DEFAULT_TOTAL_PAGES,
  USER_TABLE_HEADER,
} from "@constants/";

// Enums
import { EEntity, EFilters, EPagination, EDepartments } from "@enums/";
import { TUser } from "types/TUser";

const AllUsers = () => {
  // MARK: State
  const [search, setSearch] = useDebouncedState("", DEFAULT_DEBOUNCE_TIME);
  const [paginator, setPaginator] = useState({
    [EPagination.PER_PAGE]: DEFAULT_PER_PAGE,
    [EPagination.CURRENT_PAGE]: DEFAULT_CURRENT_PAGE,
    [EPagination.TOTAL]: DEFAULT_TOTAL,
    [EPagination.TOTAL_PAGES]: DEFAULT_TOTAL_PAGES,
  });
  const [usersData, setUsersData] = useState<TUser>();

  const searchValue = useMemo(() => {
    return search;
  }, [search]);

  // MARK: Functions
  const { deleteUser } = useDeleteUser();
  const { users, isLoading } = useGetAllUsers({
    [EFilters.SEARCH]: searchValue,
    [EFilters.PAGE]: paginator[EPagination.CURRENT_PAGE],
    [EFilters.PER_PAGE]: paginator[EPagination.PER_PAGE],
  });

  const onPageChange = (newPage: number) => {
    setPaginator((prev) => {
      return { ...prev, [EPagination.CURRENT_PAGE]: newPage };
    });
  };

  const resetFilters = useCallback(() => {
    setSearch("");
  }, [setSearch]);

  // MARK: Effects
  // Set initial pagination from backend
  useEffect(() => {
    if (!users || users?.length === 0) return;
    setPaginator(users?.pagination);

    const transformetData = users.data.map((item: TUser) => {
      return {
        ...item,
        departments: generateDepartmentLabels(item),
      };
    });

    setUsersData(transformetData);
  }, [users]);

  const generateDepartmentLabels = (singleUserData: TUser) => {
    const departmentLabels: string[] = [];

    if (singleUserData.departments && singleUserData.departments.length !== 0) {
      singleUserData.departments.forEach((singleDepartment) => {
        departmentLabels.push(
          EDepartments[singleDepartment as number]
            .replace(/_/g, " ")
            .toLowerCase()
            .replace(/^\w/, (c) => c.toUpperCase()),
        );
      });
    }

    return departmentLabels.join(", ");
  };

  // MARK: Renders
  if (isLoading) return <TableSkeleton />;

  return (
    <section>
      <PageHeader showCreateBtn title="Users" entity={EEntity.USERS} />

      <Filters onReset={resetFilters}>
        <form>
          <TextInput
            defaultValue={search}
            onChange={(event) => setSearch(event?.currentTarget?.value)}
            placeholder="Search by user name..."
            variant="unstyled"
          />
        </form>
      </Filters>

      <div className="w-full">
        <CustomTable
          columns={USER_TABLE_HEADER}
          entity={EEntity.USERS}
          handleDelete={deleteUser}
          onPageChange={onPageChange}
          paginator={users.pagination}
          showEdit
          tableData={usersData as unknown as unknown[]}
        />
      </div>
    </section>
  );
};

export { AllUsers };

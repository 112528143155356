import { User } from "lucide-react";

type TUserChatMessage = {
  message: string;
};

const UserChatMessage = ({ message }: TUserChatMessage) => {
  return (
    <div className="flex h-fit w-full p-2 pl-28">
      <div className="mr-sm h-full w-full rounded-md bg-braytron-10 px-3 py-2 text-sm">
        <p>{message}</p>
      </div>

      <div className="flex h-9 w-9 shrink-0 items-center justify-center rounded-full bg-braytron-60">
        <User className="h-5 w-5 text-white" />
      </div>
    </div>
  );
};

export { UserChatMessage };

import { Route, Routes } from "react-router-dom";

// Pages
import {
  BenefitRoutes,
  ClassificationRoutes,
  Home,
  NotFound,
  RanksRoutes,
  RequirementsRoutes,
  UserRoutes,
} from "@pages/";

// Layouts
import { AppShell, ErrorWrapper } from "@layouts/";

// Constants
import { CLIENT_ROUTES } from "@constants/";
import { Configuration } from "./Configuration/Configuration";
import { ChatAI } from "@components/";

const AppRouter = () => {
  return (
    <AppShell>
      <ErrorWrapper>
        <Routes>
          <Route index element={<Home />} />
          <Route path={`${CLIENT_ROUTES.loyalty.classifications}/*`} element={<ClassificationRoutes />} />
          <Route path={`${CLIENT_ROUTES.loyalty.requirements}/*`} element={<RequirementsRoutes />} />
          <Route path={`${CLIENT_ROUTES.loyalty.benefits}/*`} element={<BenefitRoutes />} />
          <Route path={`${CLIENT_ROUTES.loyalty.ranks}/*`} element={<RanksRoutes />} />
          <Route path={`${CLIENT_ROUTES.administration.users}/*`} element={<UserRoutes />} />

          <Route path={`${CLIENT_ROUTES.configuration}/*`} element={<Configuration />} />

          <Route path="*" element={<NotFound />} />
        </Routes>

        <ChatAI />
      </ErrorWrapper>
    </AppShell>
  );
};

export { AppRouter };

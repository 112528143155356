const LABEL_EMAIL = "Email";
const LABEL_NAME = "Name";
const LABEL_PASSWORD = "Password";
const LABEL_PASSWORD_CONFIRMATION = "Password confirmation";
const LABEL_TITLE = "Title";
const LABEL_LINK = "Link";
const LABEL_SQL_QUERIES = "SQL queries";
const LABEL_GRAPHQL_QUERIES = "GraphQL queries";
const LABEL_XML = "XML";

export {
  LABEL_NAME,
  LABEL_EMAIL,
  LABEL_PASSWORD,
  LABEL_PASSWORD_CONFIRMATION,
  LABEL_TITLE,
  LABEL_LINK,
  LABEL_SQL_QUERIES,
  LABEL_GRAPHQL_QUERIES,
  LABEL_XML,
};

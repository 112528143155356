// Utils
import { api, removeEmptyFromObject } from "@utils/";

// Constants
import { API_ROUTES } from "@constants/";

// Types
import { TFolderFilter } from "@_types/";

const fetchUser = async (id: string) => {
  const response = await api.get(`${API_ROUTES.user}/${id}`, {});
  return response?.data;
};

const getAllUsers = async ({ filters }: { filters?: TFolderFilter[] | TFolderFilter }) => {
  const data = removeEmptyFromObject({
    ...filters,
  });

  const response = await api.get(API_ROUTES.user, data);

  return response?.data;
};

const deleteUser = async (id: string) => {
  const response = await api.destroy(`${API_ROUTES.users}/${id}`);
  return response;
};

const createUser = async (data: unknown) => {
  const response = await api.post(API_ROUTES.users, {}, data);
  return response?.data;
};

const getOneUser = async (id: string) => {
  const response = await api.get(`${API_ROUTES.users}/${id}`, {});
  return response?.data;
};

const updateUser = async (id: string, data: unknown) => {
  const response = await api.patch(`${API_ROUTES.users}/${id}`, {}, data);
  return response?.data;
};

export { fetchUser, getAllUsers, deleteUser, createUser, getOneUser, updateUser };

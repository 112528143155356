// Utils
import { api } from "@utils/";

// Constants
import { API_ROUTES } from "@constants/";

// Types
import { IAuthLogin, IRequestPasswordReset, IResetPassword } from "@interfaces/";

const loginRequest = async (data: IAuthLogin) => {
  const response = await api.post(API_ROUTES.login, {}, data);
  return response;
};

const requestPasswordReset = async (data: IRequestPasswordReset) => {
  const response = await api.post(API_ROUTES.requestPasswordReset, {}, data);
  return response;
};

const resetPassword = async (data: IResetPassword) => {
  const response = await api.post(API_ROUTES.resetPassword, {}, data);
  return response;
};

export { loginRequest, requestPasswordReset, resetPassword };

import { EEntity } from "@enums/";

/**
 * @description Util function to return route based on entity
 * @param entity EEntity
 * @returns string
 */
const getLinkBasedOnEntity = (entity: EEntity) => {
  switch (entity) {
    case EEntity.BENEFITS:
      return "benefits";
    case EEntity.CLASSIFICATION:
      return "classifications";
    case EEntity.RANKS:
      return "ranks";
    case EEntity.REQUIREMENTS:
      return "requirements";
    case EEntity.USERS:
      return "users";
    default:
      return "";
  }
};

export { getLinkBasedOnEntity };

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Textarea } from "@mantine/core";
import { Send } from "lucide-react";
import { useEffect, useState } from "react";

// Components
import { UserChatMessage } from "./UserChatMessage";
import { AIChatMessage } from "./AIChatMessage";
import { Visible } from "@components/";

// Enums
import { EChat } from "@enums/";

// Hooks
import { useSocket } from "@hooks/";

// Constants
import { LS_UUID, WS_SEND_MESSAGE } from "@constants/";

// Utiils
import { getItem } from "@utils/";
import { ChatLoading } from "./ChatLoading";
import { ChatHistory } from "./ChatHistory";

type TChatMessages = {
  message: string;
  type: EChat;
};

const ChatAIContent = () => {
  const uuid = getItem(LS_UUID);
  const socket = useSocket();
  const [message, setMessage] = useState("");
  const [chatMessages, setChatMessages] = useState<TChatMessages[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const onSendMessage = async () => {
    if (!message && !socket) return;

    (socket as any)?.emit(WS_SEND_MESSAGE, {
      prompt: message,
      uuid: uuid,
    });

    setChatMessages((prevMessages) => [...prevMessages, { message, type: EChat.USER }]);
    setMessage("");
    setIsLoading(true);
  };

  useEffect(() => {
    (socket as any)?.on(uuid, (data: any) => {
      const { data: message } = JSON.parse(data);
      setChatMessages((prevMessages) => [...prevMessages, { message, type: EChat.AI }]);
      setIsLoading(false);
    });

    return () => {
      (socket as any)?.disconnect();
    };
  }, [socket, uuid]);

  return (
    <div className="flex h-[calc(100vh-80px)] w-full gap-x-2">
      <ChatHistory chatHistory={chatMessages} />
      <section className="flex h-full w-full flex-col transition-all duration-300 ease-in-out">
        {/* Message list */}
        <section className="max-h-[calc(100vh-10rem)] flex-grow overflow-y-auto pr-sm">
          <Visible when={chatMessages.length === 0}>
            <p className="flex w-full items-center justify-center py-8 text-braytron-darkGray">What can i help with?</p>
          </Visible>

          <Visible when={chatMessages.length !== 0}>
            {chatMessages.map((msg: TChatMessages, index) => {
              if (msg.type === EChat.USER) {
                return <UserChatMessage key={index} message={msg.message} />;
              } else {
                return <AIChatMessage key={index} message={msg.message} />;
              }
            })}
          </Visible>
          <Visible when={isLoading}>
            <ChatLoading />
          </Visible>
        </section>

        {/* Input message */}
        <section className="flex w-full rounded-md border border-braytron-lightGray bg-offwhite p-2">
          <Textarea
            placeholder="Message BraytronChatAI"
            className="flex-grow"
            rows={4}
            value={message}
            onChange={(event) => setMessage(event.currentTarget.value)}
            styles={{
              input: {
                border: "none",
                background: "#FAFAFA",
              },
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.keyCode === 13) {
                e.preventDefault(); // Prevent adding a new line
                onSendMessage();
              }
            }}
          />

          <div className="flex h-full w-12 items-end justify-end">
            <button
              className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-braytron-60 hover:bg-braytron-80"
              onClick={() => {
                onSendMessage();
              }}
            >
              <Send className="h-5 w-5 text-white" />
            </button>
          </div>
        </section>
      </section>
    </div>
  );
};

export { ChatAIContent };

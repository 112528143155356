import { Button, Divider, Stack, TextInput, Title } from "@mantine/core";
import { useField } from "@mantine/form";
import { Save } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Query Builder
import { QueryBuilderMantine } from "@react-querybuilder/mantine";
import { formatQuery, FormatQueryOptions, QueryBuilder, type RuleGroupTypeIC, type RuleType } from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";

// Components
import { FormSkeleton, LoadingOverlay, PageHeader } from "@components/";

// Enums
import { EFilters, ESize } from "@enums/";

// api
import { createRequirement, updateRequirement, useGetOneRequirement, useGetSelectedConfiguration } from "@api/";

// utils
import { error, success } from "@utils/";

enum ERequirementFormFields {
  NAME = "name",
  QUERY_BUILDER = "query_builder",
}

const initialQuery: RuleGroupTypeIC = { rules: [] };
const previewType = "json_without_ids";

const RequirementForm = ({ edit }: { edit: boolean }) => {
  const [query, setQuery] = useState<RuleGroupTypeIC>(initialQuery);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { folderId, requirementId } = useParams();
  const [queryBuilderFields, setQueryBuilderFields] = useState([]);

  const field = useField({
    mode: "uncontrolled",
    initialValue: "",
    validate: (value) => {
      return value.trim().length < 3 ? "Title is too short" : null;
    },
  });
  const title = field.getValue();

  const navigate = useNavigate();

  // In case of edit, call getOne requirement
  const { requirement, isLoading } = useGetOneRequirement({
    id: requirementId,
  });

  const { configuration } = useGetSelectedConfiguration({
    [EFilters.SEARCH]: "",
    is_active: 1,
    [EFilters.PAGE]: 1,
    [EFilters.PER_PAGE]: 100,
  });

  useEffect(() => {
    if (configuration?.data) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const transformedData = configuration.data.map((item: any) => ({
        ...item,
        name: item.id.toString(),
        label: `${item.table_name}.${item.column_name}`,
      }));

      setQueryBuilderFields(transformedData);
    }
  }, [configuration]);

  useEffect(() => {
    if (requirement?.data?.query_builder && edit) {
      const JSON_FROM_API = requirement?.data?.query_builder;
      setQuery(JSON_FROM_API as unknown as RuleGroupTypeIC<RuleType<string, string, string, string>, string>);
    }
  }, [requirement, edit]);

  // TODO : ako je greska loading traje zauvek nema handle error-a
  const onSubmit = async () => {
    setIsSubmitting(true);

    if (edit) {
      const response = await updateRequirement(requirementId!, {
        [ERequirementFormFields.NAME]: title,
        [ERequirementFormFields.QUERY_BUILDER]: formatQuery(query, previewType as FormatQueryOptions),
        folder_id: parseInt(folderId!),
      });
      if (response) {
        setIsSubmitting(false);
        success({ title: "Success", message: response?.message });
        navigate(`/requirements/folder/${folderId}`);
      }
    } else {
      // Problem kad se ne popuni query builder i dalje ima default vrednost (1==1) i prolazi validaciju
      const response = await createRequirement({
        [ERequirementFormFields.NAME]: title,
        [ERequirementFormFields.QUERY_BUILDER]: formatQuery(query, previewType as FormatQueryOptions),
        folder_id: parseInt(folderId!),
      });

      if (response) {
        setIsSubmitting(false);
        success({ title: "Success", message: response?.message });
        navigate(`/requirements/folder/${folderId}`);
      } else {
        setIsSubmitting(false);
        error({ message: response?.error?.message });
      }
    }
  };

  /**
   * If form is in edit mode
   * Populate data
   */
  useEffect(() => {
    if (!edit) return;
    field.setValue(requirement?.data?.name);
  }, [requirement, edit, field]);

  if (isLoading && edit) return <FormSkeleton />;

  return (
    <>
      <LoadingOverlay visible={isSubmitting} />
      <PageHeader
        title={edit ? "Edit Requirement" : "Add Requirement"}
        showBackBtn
        additionalHeaderContent={
          <Button
            onClick={async () => {
              const isError = await field.validate();

              if (isError) {
                return;
              }

              if (query?.rules.length === 0) {
                error({ title: "Query is required." });
                return;
              }

              await onSubmit();
            }}
            className="filled"
            leftSection={<Save className="h-5 w-5 text-white" />}
          >
            Save
          </Button>
        }
      />
      <section className="w-full p-md pt-md">
        <Stack gap={ESize.MD} mt={ESize.MD}>
          <Stack>
            <TextInput {...field.getInputProps()} key={field.key} placeholder="Requirement title" label="Title" />
          </Stack>
          <Divider my={ESize.SM} />
          <Stack mt="-0.5rem">
            <Title order={4}>Query builder</Title>
            <QueryBuilderMantine>
              <QueryBuilder
                fields={queryBuilderFields}
                query={query}
                onQueryChange={setQuery}
                showCombinatorsBetweenRules
                controlClassnames={{
                  queryBuilder: "queryBuilder-branches",
                  fields: "w-full",
                  operators: "w-1/3",
                  value: "w-1/3",
                  ruleGroup: "bg-braytron-10/50 p-4 rounded-lg border-braytron-50",
                  removeRule: "!bg-[#FFFFFF00] !hover:text-red !text-braytron-50 text-3xl p-0 pb-3 pl-2 w-12",
                }}
              />
            </QueryBuilderMantine>
          </Stack>
        </Stack>
      </section>
    </>
  );
};

export { RequirementForm };

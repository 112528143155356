import { Drawer } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { MessageCircleMore } from "lucide-react";

// Components
import { ChatAIContent } from "./ChatAIContent";

// Contexts
import { ChatProvider, SocketProvider } from "@contexts/";

const ChatAI = () => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <div
        onClick={open}
        className="fixed bottom-8 right-5 z-[99] flex h-10 w-32 cursor-pointer items-center justify-between rounded-3xl bg-braytron-80 px-2 pr-sm text-white hover:bg-braytron-90"
      >
        <div className="mr-xxs flex h-7 w-7 items-center justify-center rounded-full bg-braytron-100">
          <MessageCircleMore className="h-4" />
        </div>
        <p className="text-sm">Chat with AI</p>
      </div>

      <Drawer
        keepMounted
        opened={opened}
        onClose={close}
        title="BraytronChat AI"
        position="right"
        size="xl"
        overlayProps={{ backgroundOpacity: 0.5, blur: 1 }}
      >
        <SocketProvider>
          <ChatProvider>
            <ChatAIContent />
          </ChatProvider>
        </SocketProvider>
      </Drawer>
    </>
  );
};

export { ChatAI };

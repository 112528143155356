import { Button, PasswordInput, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useState } from "react";
import { z } from "zod";
import { useLocation, useNavigate } from "react-router-dom";

// Constants
import {
  CLIENT_ROUTES,
  EMAIL,
  LABEL_EMAIL,
  LABEL_PASSWORD,
  LABEL_PASSWORD_CONFIRMATION,
  NOT_EMPTY,
  PLACEHOLDER_EMAIL,
  PLACEHOLDER_PASSWORD,
} from "@constants/";

// Components
import { Logo } from "@components/";

// Requests
import { resetPassword } from "@api/";

// Utils
import { error, success } from "@utils/";

type TResetPassword = z.infer<typeof schema>;

enum EResetPasswordFormFields {
  EMAIL = "email",
  PASSWORD = "password",
  PASSWORD_CONFIRMATION = "password_confirmation",
  TOKEN = "token",
}

const schema = z.object({
  [EResetPasswordFormFields.EMAIL]: z.string({ required_error: NOT_EMPTY }).email({ message: EMAIL }),
  [EResetPasswordFormFields.PASSWORD]: z.string({ required_error: NOT_EMPTY }),
  [EResetPasswordFormFields.PASSWORD_CONFIRMATION]: z.string({ required_error: NOT_EMPTY }),
});

function ResetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const token = new URLSearchParams(location.search).get("token");

  const form = useForm({
    initialValues: {
      [EResetPasswordFormFields.EMAIL]: "",
      [EResetPasswordFormFields.PASSWORD]: "",
      [EResetPasswordFormFields.PASSWORD_CONFIRMATION]: "",
      honeypot: false,
    },
    validate: zodResolver(schema),
  });

  const onResetPassword = async (values: TResetPassword) => {
    setIsLoading(true);
    const { email, password, password_confirmation } = values;

    const response = await resetPassword({
      [EResetPasswordFormFields.EMAIL]: email,
      [EResetPasswordFormFields.PASSWORD]: password,
      [EResetPasswordFormFields.PASSWORD_CONFIRMATION]: password_confirmation,
      [EResetPasswordFormFields.TOKEN]: token ? token : "",
    });

    if (response) {
      setIsLoading(false);
      success({
        title: "Reset password is successfully!",
      });
      navigate(CLIENT_ROUTES.login);
    } else {
      setIsLoading(false);
      error({ title: "Reset password failed." });
    }
  };

  return (
    <div className="flex h-screen w-screen items-center justify-center bg-offwhite">
      <article className="w-full max-w-[30rem] border-[1px] border-braytron-lightGray bg-white p-lg px-xl py-2xl">
        <div className="flex w-full items-center justify-center pb-lg">
          <Logo disableLink size="text-2xl" />
        </div>
        <div className="mb-xl flex w-full items-center justify-center text-2xl">
          <p>Reset password</p>
        </div>
        <form
          className="mt-md flex h-full w-full flex-col gap-y-md"
          onSubmit={form.onSubmit((values) => {
            if (values.honeypot) return;
            onResetPassword(values);
          })}
        >
          <div className="flex w-full flex-col gap-y-md">
            <TextInput
              autoComplete="email"
              label={LABEL_EMAIL}
              placeholder={PLACEHOLDER_EMAIL}
              {...form.getInputProps(EResetPasswordFormFields.EMAIL)}
            />

            <PasswordInput
              autoComplete="new-password"
              label={LABEL_PASSWORD}
              placeholder={PLACEHOLDER_PASSWORD}
              {...form.getInputProps(EResetPasswordFormFields.PASSWORD)}
            />

            <PasswordInput
              autoComplete="new-password"
              label={LABEL_PASSWORD_CONFIRMATION}
              placeholder={PLACEHOLDER_PASSWORD}
              {...form.getInputProps(EResetPasswordFormFields.PASSWORD_CONFIRMATION)}
            />

            <div className="mb-xl flex w-full cursor-pointer justify-end hover:text-braytron-90">
              <p
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(CLIENT_ROUTES.login);
                }}
              >
                Do you have an account? Sign in?
              </p>
            </div>
          </div>

          <Button loading={isLoading} type="submit">
            Reset password
          </Button>
        </form>
      </article>
    </div>
  );
}

export { ResetPassword };

/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useEffect, useState, type PropsWithChildren } from "react";
import { io } from "socket.io-client";

// Constants
import { WS_URL } from "@constants/";

export const SocketContext = createContext(null);

function SocketProvider({ children }: PropsWithChildren) {
  const [socket, setSocket] = useState<any>();

  useEffect(() => {
    const NEW_SOCKET: any = io(WS_URL, {
      autoConnect: true,
    });

    setSocket(NEW_SOCKET);

    return () => {
      NEW_SOCKET.disconnect();
    };
  }, []);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
}

export { SocketProvider };

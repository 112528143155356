export enum EDepartments {
  ACCOUNTING_AND_FINANCE = 1,
  IMPORT_EXPORT = 2,
  ANALYTICS = 3,
  SALES = 4,
  MARKETING = 5,
  ADMINISTRATION = 6,
  MANAGEMENT = 7,
}

export const allDepartments = [
  {
    label: "Accounting and finance",
    value: EDepartments.ACCOUNTING_AND_FINANCE.toString(),
  },
  {
    label: "Import / Export",
    value: EDepartments.IMPORT_EXPORT.toString(),
  },
  {
    label: "Analytics",
    value: EDepartments.ANALYTICS.toString(),
  },
  {
    label: "Sales",
    value: EDepartments.SALES.toString(),
  },
  {
    label: "Marketing",
    value: EDepartments.MARKETING.toString(),
  },
  {
    label: "Administration",
    value: EDepartments.ADMINISTRATION.toString(),
  },
  {
    label: "Management",
    value: EDepartments.MANAGEMENT.toString(),
  },
];

import { Routes, Route } from "react-router-dom";
import { AllUsers } from "./AllUsers";
import { NotFound } from "@pages/";
import { AddUser } from "./AddUser";
import { EditUser } from "./EditUser";

// Pages

const UserRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AllUsers />} />
      <Route path="/add" element={<AddUser />} />
      <Route path="/edit/:userId" element={<EditUser />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export { UserRoutes };

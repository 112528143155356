import clsx from "clsx";
import { FC, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// Components
import {
  Box,
  Collapse,
  Flex,
  Group,
  AppShell as MantineAppShell,
  ScrollArea,
  Text,
  ThemeIcon,
  UnstyledButton,
} from "@mantine/core";
import { ChevronDown, HandHelping, LogOut, Settings, User } from "lucide-react";

// Enums
import { ESize } from "@enums/";

// Hooks
import { useLogout } from "@hooks/";

// Components
import { SidebarFooter } from "./SidebarFooter";

// Constants
import { CLIENT_ROUTES } from "@constants/";

interface LinksGroupProps {
  icon: FC;
  label: string;
  initiallyOpened?: boolean;
  links?: { label: string; link: string; active?: boolean }[];
}

export function LinksGroup({ icon: Icon, label, initiallyOpened, links }: LinksGroupProps) {
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(initiallyOpened || false);

  const items = (hasLinks ? links : []).map((link) => (
    <Text
      component={Link}
      className={clsx(
        "flex border-l border-l-braytron-30 text-braytron-darkGray hover:bg-braytron-10/50",
        link?.active && "bg-braytron-10 !text-braytron-100",
      )}
      to={link.link}
      key={link.label}
      pl={ESize.MD}
      py={ESize.XS}
    >
      {link.label}
    </Text>
  ));

  return (
    <>
      <UnstyledButton onClick={() => setOpened((o) => !o)} className="w-full">
        <Group justify="space-between" gap={0}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <ThemeIcon variant="light" size={30} radius={ESize.MD}>
              <Icon />
            </ThemeIcon>
            <Box ml={ESize.MD}>{label}</Box>
          </Box>
          {hasLinks && <ChevronDown className="h-5 w-5" />}
        </Group>
      </UnstyledButton>
      {hasLinks ? (
        <div className="py-xs">
          <Collapse in={opened} pl={ESize.SM}>
            {items}
          </Collapse>
        </div>
      ) : null}
    </>
  );
}

const Sidebar = () => {
  const { pathname } = useLocation();
  const { logoutUser } = useLogout();
  const navigate = useNavigate();

  const PAGES = useMemo(() => {
    return [
      {
        label: "Loyalty Program",
        icon: HandHelping,
        initiallyOpened: true,
        links: [
          {
            label: "Classifications",
            link: CLIENT_ROUTES.loyalty.classifications,
            active: pathname.includes(CLIENT_ROUTES.loyalty.classifications),
          },
          {
            label: "Ranks",
            link: CLIENT_ROUTES.loyalty.ranks,
            active: pathname.includes(CLIENT_ROUTES.loyalty.ranks),
          },
          {
            label: "Requirements",
            link: CLIENT_ROUTES.loyalty.requirements,
            active: pathname.includes(CLIENT_ROUTES.loyalty.requirements),
          },
          {
            label: "Benefits",
            link: CLIENT_ROUTES.loyalty.benefits,
            active: pathname.includes(CLIENT_ROUTES.loyalty.benefits),
          },
        ],
      },
      {
        label: "Administration",
        icon: User,
        initiallyOpened: true,
        links: [
          {
            label: "Users",
            link: CLIENT_ROUTES.administration.users,
            active: pathname.includes(CLIENT_ROUTES.administration.users),
          },
        ],
      },
    ];
  }, [pathname]);

  const links = PAGES.map((item) => <LinksGroup {...item} key={item.label} />);

  return (
    <MantineAppShell.Navbar className="pt-sm">
      {/* Sidebar links */}
      <ScrollArea className="flex-1 p-4">
        <>{links}</>
      </ScrollArea>
      {/* Actions */}
      <Flex align="center" h={90} w="100%" gap={15} px={ESize.SM} direction="column">
        <UnstyledButton className="w-full">
          <Group
            justify="space-between"
            gap={0}
            onClick={() => {
              navigate(CLIENT_ROUTES.configuration);
            }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <ThemeIcon variant="light" size={30} radius={ESize.MD}>
                <Settings className="h-5" />
              </ThemeIcon>
              <Box
                ml={ESize.MD}
                className={` ${pathname.includes(CLIENT_ROUTES.configuration) && "text-braytron-100"}`}
              >
                Configuration
              </Box>
            </Box>
          </Group>
        </UnstyledButton>
        <UnstyledButton className="w-full">
          <Group justify="space-between" gap={0} onClick={logoutUser}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <ThemeIcon variant="light" size={30} radius={ESize.MD}>
                <LogOut className="h-5" />
              </ThemeIcon>
              <Box ml={ESize.MD}>Logout</Box>
            </Box>
          </Group>
        </UnstyledButton>
      </Flex>
      <SidebarFooter />
    </MantineAppShell.Navbar>
  );
};

export { Sidebar };

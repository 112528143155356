import { createContext, type PropsWithChildren } from "react";

export const ChatContext = createContext({
  messages: [],
  isLoading: false,
});

function ChatProvider({ children }: PropsWithChildren) {
  return <ChatContext.Provider value={{ messages: [], isLoading: false }}>{children}</ChatContext.Provider>;
}

export { ChatProvider };

import { Brain } from "lucide-react";

const ChatLoading = () => {
  return (
    <div className="flex h-fit w-full p-2 pr-28">
      <div className="flex h-9 w-9 items-center justify-center rounded-full bg-braytron-textDark">
        <Brain className="h-5 w-5 text-white" />
      </div>

      <div className="ml-sm flex h-full w-full gap-x-2 rounded-md px-3 py-2 pt-md text-sm">
        <div className="h-3 w-3 animate-bounce rounded-full bg-braytron-textDark [animation-delay:-0.3s]"></div>
        <div className="h-3 w-3 animate-bounce rounded-full bg-braytron-textDark [animation-delay:-0.15s]"></div>
        <div className="h-3 w-3 animate-bounce rounded-full bg-braytron-textDark"></div>
      </div>
    </div>
  );
};

export { ChatLoading };

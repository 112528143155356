// Interfaces
import { EActionAuthType } from "@interfaces/";

// Constatns

// Hooks
import { useAuth } from "@hooks/";

export const useLogout = () => {
  const { dispatch } = useAuth();

  const logoutUser = async () => {
    dispatch({
      type: EActionAuthType.LOGOUT,
      payload: {
        id: null,
        uuid: null,
        token: "",
        isAuthenticated: false,
      },
    });
  };

  return { logoutUser };
};

// MARK: Table headers
const BENEFITS_TABLE_HEADER = [
  {
    header: "ID",
    field: "id",
  },
  {
    header: "Name",
    field: "name",
  },
];

const CLASSIFICATIONS_TABLE_HEADER = [
  {
    header: "ID",
    field: "id",
  },
  {
    header: "Name",
    field: "title",
  },
];

const RANKS_TABLE_HEADER = [
  {
    header: "ID",
    field: "id",
  },
  {
    header: "Name",
    field: "name",
  },
  {
    header: "Number of benefits",
    field: "numberOfBenefits",
  },
  {
    header: "Number of requirements",
    field: "numberOfRequirements",
  },
  {
    header: "Schedule type",
    field: "scheduleType",
  },
];

const REQUIREMENTS_TABLE_HEADER = [
  {
    header: "ID",
    field: "id",
  },
  {
    header: "Name",
    field: "name",
  },
];

const CONFIGURATION_TABLE_HEADER = [
  {
    header: "ID",
    field: "id",
  },
  {
    header: "Table-Column name",
    field: "table_column",
  },
  {
    header: "Table",
    field: "table_name",
  },
  {
    header: "Column",
    field: "column_name",
  },
  {
    header: "Change status",
    field: "status",
    custom: {
      type: "button",
      tooltip: "Remove from favorite",
      icon: "HeartOff",
    },
  },
];

const USER_TABLE_HEADER = [
  {
    header: "ID",
    field: "id",
  },
  {
    header: "Name",
    field: "name",
  },
  {
    header: "Email",
    field: "email",
  },
  {
    header: "Departments",
    field: "departments",
  },
];
// MARK: Table default values
const DEFAULT_PER_PAGE = 10;
const DEFAULT_CURRENT_PAGE = 1;
const DEFAULT_TOTAL = 0;
const DEFAULT_TOTAL_PAGES = 0;

export {
  BENEFITS_TABLE_HEADER,
  CLASSIFICATIONS_TABLE_HEADER,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PER_PAGE,
  DEFAULT_TOTAL,
  DEFAULT_TOTAL_PAGES,
  RANKS_TABLE_HEADER,
  REQUIREMENTS_TABLE_HEADER,
  CONFIGURATION_TABLE_HEADER,
  USER_TABLE_HEADER,
};

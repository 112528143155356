/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from "react";

// Contexts
import { SocketContext } from "@contexts/";

export function useSocket() {
  const socket = useContext<any>(SocketContext);
  return socket;
}

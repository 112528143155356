import { Dispatch } from "react";

export enum EActionAuthType {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  SIGNUP = "SIGNUP",
}

export interface IAuthLogin {
  email: string;
  password: string;
}

export interface IRequestPasswordReset {
  email: string;
}
export interface IResetPassword {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
}

export interface IAuthSignup {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
}

export interface IState {
  token: string | null;
  id: string | null;
  uuid: string | null;
  isAuthenticated: boolean;
}

export interface IAction {
  type: EActionAuthType;
  payload: IState;
}

export interface IInitialState {
  token: string | null;
  id: string | null;
  uuid: string | null;
  isAuthenticated: boolean;
  dispatch: Dispatch<IAction>;
}

export type Reducer<S, A> = (prevState: S, action: A) => S;

import { Button, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useState } from "react";
import { z } from "zod";
import { useNavigate } from "react-router-dom";

// Constants
import { CLIENT_ROUTES, EMAIL, LABEL_EMAIL, NOT_EMPTY, PLACEHOLDER_EMAIL } from "@constants/";

// Components
import { Logo } from "@components/";

// Requests
import { requestPasswordReset } from "@api/";

// Utils
import { error, success } from "@utils/";

type TRequestReset = z.infer<typeof schema>;

enum ERequestResetFormFields {
  EMAIL = "email",
}

const schema = z.object({
  [ERequestResetFormFields.EMAIL]: z.string({ required_error: NOT_EMPTY }).email({ message: EMAIL }),
});

function RequestPasswordReset() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      [ERequestResetFormFields.EMAIL]: "",
      honeypot: false,
    },
    validate: zodResolver(schema),
  });

  const onRequestPasswordReset = async (values: TRequestReset) => {
    setIsLoading(true);
    const { email } = values;

    const response = await requestPasswordReset({
      [ERequestResetFormFields.EMAIL]: email,
    });

    if (response) {
      setIsLoading(false);
      success({
        title: "Reset password request is successfully. Check your email!",
      });
    } else {
      setIsLoading(false);
      error({ title: "Reset password request failed." });
    }
  };

  return (
    <div className="flex h-screen w-screen items-center justify-center bg-offwhite">
      <article className="w-full max-w-[30rem] border-[1px] border-braytron-lightGray bg-white p-lg px-xl py-2xl">
        <div className="flex w-full items-center justify-center pb-lg">
          <Logo disableLink size="text-2xl" />
        </div>
        <div className="mb-xl flex w-full items-center justify-center text-2xl">
          <p>Request password reset</p>
        </div>
        <form
          className="mt-md flex h-full w-full flex-col gap-y-md"
          onSubmit={form.onSubmit((values) => {
            if (values.honeypot) return;
            onRequestPasswordReset(values);
          })}
        >
          <div className="w-full">
            <TextInput
              autoComplete="email"
              label={LABEL_EMAIL}
              placeholder={PLACEHOLDER_EMAIL}
              {...form.getInputProps(ERequestResetFormFields.EMAIL)}
            />

            <div className="flex w-full cursor-pointer justify-end pt-sm hover:text-braytron-90">
              <p
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(CLIENT_ROUTES.login);
                }}
              >
                Do you have an account? Sign in?
              </p>
            </div>
          </div>

          <Button loading={isLoading} type="submit">
            Request password reset
          </Button>
        </form>
      </article>
    </div>
  );
}

export { RequestPasswordReset };

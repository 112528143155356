import { Button, MultiSelect, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { Save } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

// Components
import { FormSkeleton, PageHeader } from "@components/";

// Enums
import { allDepartments, EEntity } from "@enums/";

// Api
import { createUser, updateUser, useGetOneUser } from "@api/";

// Utils
import { isEmpty, success } from "@utils/";

// Constants
import { EMAIL, MIN_THREE_CHARACTERS, NOT_EMPTY } from "@constants/";

enum EUserFormFields {
  NAME = "name",
  EMAIL = "email",
  DEPARTMENTS = "departments",
}

const schema = z.object({
  [EUserFormFields.NAME]: z.string({ required_error: NOT_EMPTY }).min(3, { message: MIN_THREE_CHARACTERS }),
  [EUserFormFields.EMAIL]: z.string({ required_error: NOT_EMPTY }).email({ message: EMAIL }),
  [EUserFormFields.DEPARTMENTS]: z.array(z.string()).nonempty(),
});

type TUser = z.infer<typeof schema>;

type UserFormProps = { edit: boolean };

const UserForm = ({ edit }: UserFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { userId } = useParams();
  const navigate = useNavigate();

  // In case of edit, call getOne benefit
  const { user, isLoading } = useGetOneUser({
    id: userId,
  });

  const form = useForm({
    initialValues: {
      [EUserFormFields.NAME]: "",
      [EUserFormFields.EMAIL]: "",
      [EUserFormFields.DEPARTMENTS]: [],
    },
    validate: zodResolver(schema),
  });

  const onSubmit = async (values: TUser) => {
    if (edit) {
      const response = await updateUser(userId!, {
        [EUserFormFields.NAME]: values.name,
        [EUserFormFields.DEPARTMENTS]: values.departments.map(Number),
        [EUserFormFields.EMAIL]: values.email,
      });
      if (response) {
        setIsSubmitting(false);
        success({ title: "Success", message: response?.message });
        navigate(`/users/`);
      }
    } else {
      const response = await createUser({
        [EUserFormFields.NAME]: values.name,
        [EUserFormFields.DEPARTMENTS]: values.departments.map(Number),
        [EUserFormFields.EMAIL]: values.email,
      });

      if (response) {
        setIsSubmitting(false);
        success({ title: "Success", message: response?.message });
        navigate(`/users/`);
      }
    }
  };

  /**
   * If form is in edit mode
   * Populate data
   */
  useEffect(() => {
    if (isEmpty(user) || !edit) return;
    const { data } = user;

    const departmentData: string[] = [];

    if (data.departments && data.departments.length !== 0) {
      // @ts-expect-error TODO: Tipizacija
      data?.departments.forEach((item) => {
        departmentData.push(item.toString());
      });
    }

    // LOS seed podataka, department ce uvek da ude obavezno polje, tako da sse opcija za [] skida kad reismo BE
    form.setValues({
      [EUserFormFields.NAME]: data.name,
      [EUserFormFields.EMAIL]: data.email,
      [EUserFormFields.DEPARTMENTS]:
        data.departments && data.departments.length !== 0 ? (departmentData as never[]) : [],
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, edit]);

  if (isLoading) {
    return <FormSkeleton />;
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        // @ts-expect-error TODO: Tipizacija
        onSubmit(values);
      })}
    >
      <PageHeader
        showBackBtn
        title={edit ? "Edit User" : "Add User"}
        entity={EEntity.USERS}
        additionalHeaderContent={
          <div className="flex">
            <Button
              loading={isSubmitting}
              type="submit"
              className="filled"
              leftSection={<Save className="h-5 w-5 text-white" />}
            >
              Save
            </Button>
          </div>
        }
      />
      <section className="w-full p-md pt-xl">
        <TextInput
          className="mb-2 w-full md:w-1/3"
          withAsterisk
          label="Name"
          {...form.getInputProps(EUserFormFields.NAME)}
        />
        <TextInput
          className="mb-2 w-full md:w-1/3"
          withAsterisk
          label="Email"
          {...form.getInputProps(EUserFormFields.EMAIL)}
        />

        <MultiSelect
          className="mb-2 w-full md:w-1/3"
          clearable
          data={allDepartments}
          label="Departments"
          limit={10}
          placeholder="Select"
          searchable
          withAsterisk
          {...form.getInputProps(EUserFormFields.DEPARTMENTS)}
        />
      </section>
    </form>
  );
};

export { UserForm };

import { Tooltip } from "@mantine/core";
import { Visible } from "@components/";
import { Ellipsis, PanelLeft, SquarePen } from "lucide-react";
import { useState } from "react";
import { EChat } from "@enums/*";

type TChatHistoryMessages = {
  chatHistory: TChatMessages[];
};

type TChatMessages = {
  message: string;
  type: EChat;
};
const ChatHistory = ({ chatHistory }: TChatHistoryMessages) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapseToggle = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  return (
    <section
      className={`h-full ${isCollapsed ? "w-10" : "w-64"} rounded-md border border-braytron-lightGray bg-offwhite p-2 transition-all duration-300 ease-in-out`}
    >
      <div className={`flex ${isCollapsed ? "flex-col" : "flex-row justify-between"} text-darkGray gap-y-2`}>
        <Tooltip label="Collapse" color="gray" position="right">
          <button className="hover:text-braytron-textDark" onClick={handleCollapseToggle}>
            <PanelLeft />
          </button>
        </Tooltip>

        <Tooltip label="New chat" color="gray" position="right">
          <button className="hover:text-braytron-textDark">
            <SquarePen />
          </button>
        </Tooltip>
      </div>

      <Visible when={!isCollapsed}>
        <div className="h-[calc(100vh-150px)] overflow-y-auto pt-md">
          <p className="text-darkGray mb-xl text-sm">Chat History</p>

          <div className="text-sm">
            <Visible when={chatHistory.length !== 0}>
              {chatHistory.map((msg: TChatMessages, index) => {
                return (
                  <Tooltip label={msg.message} color="gray" position="right" key={index}>
                    <div className="mb-xxs flex w-full cursor-pointer items-center justify-between rounded-md p-1 hover:bg-braytron-lightGray">
                      <p className="w-full max-w-32 overflow-hidden text-ellipsis whitespace-nowrap">{msg.message}</p>

                      <Ellipsis className="w-4" />
                    </div>
                  </Tooltip>
                );
              })}
            </Visible>
          </div>
        </div>
      </Visible>
    </section>
  );
};

export { ChatHistory };

import { Button } from "@mantine/core";
import { ChevronLeft, Plus } from "lucide-react";
import { ReactNode, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Hooks
import { Visible } from "@components/";

// Enums
import { EEntity } from "@enums/";

type TPageHeaderProps = {
  additionalHeaderContent?: ReactNode;
  badgeContent?: string | number;
  entity?: EEntity;
  openCreateFolder?: () => void;
  showBackBtn?: boolean;
  showBadge?: boolean;
  showCreateBtn?: boolean;
  showCreateFolderBtn?: boolean;
  title: string;
};

const PageHeader = ({
  additionalHeaderContent,
  badgeContent,
  entity,
  openCreateFolder,
  showBackBtn = false,
  showBadge = false,
  showCreateBtn = false,
  showCreateFolderBtn = false,
  title,
}: TPageHeaderProps) => {
  const navigate = useNavigate();
  const { folderId } = useParams();

  const ADD_ROUTE = useMemo(() => {
    switch (entity) {
      case EEntity.BENEFITS:
        return `/benefits/folder/${folderId}/add`;
      case EEntity.REQUIREMENTS:
        return `/requirements/folder/${folderId}/add`;
      case EEntity.CLASSIFICATION:
        return `/classifications/add`;
      case EEntity.RANKS:
        return `/ranks/add`;
      case EEntity.USERS:
        return `/users/add`;
      default:
        return "";
    }
  }, [entity, folderId]);

  return (
    <div className="flex w-full items-center justify-between border-b border-braytron-lightGray bg-white p-md">
      <div className="flex items-center">
        <Visible when={showBackBtn}>
          <Button
            // onClick={() => {
            //   navigate(getBasePath(pathname));
            // }}
            onClick={() => {
              navigate(-1);
            }}
            variant="light"
            size="compact-sm"
            className="mr-4"
          >
            <ChevronLeft className="h-5 w-5 text-braytron-textDark" />
          </Button>
        </Visible>
        <p className="text-md md:text-2xl">
          {title}

          <Visible when={showBadge}>
            <span className="bg-lightGray ml-2 rounded-lg px-2 py-1 text-xs">{badgeContent}</span>
          </Visible>
        </p>
      </div>

      <div>
        {additionalHeaderContent}

        <Visible when={showCreateFolderBtn && !!openCreateFolder}>
          <Button onClick={openCreateFolder} className="filled" leftSection={<Plus className="h-5 w-5 text-white" />}>
            Create Folder
          </Button>
        </Visible>

        <Visible when={showCreateBtn && !!entity}>
          <Button
            onClick={() => {
              navigate(ADD_ROUTE);
            }}
            className="filled"
            leftSection={<Plus className="h-5 w-5 text-white" />}
          >
            Create
          </Button>
        </Visible>
      </div>
    </div>
  );
};

export { PageHeader };

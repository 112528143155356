import { Brain } from "lucide-react";

type TAIChatMessage = {
  message: string;
};

const AIChatMessage = ({ message }: TAIChatMessage) => {
  return (
    <div className="flex h-fit w-full p-2 pr-28">
      <div className="flex h-9 w-9 shrink-0 items-center justify-center rounded-full bg-braytron-textDark">
        <Brain className="h-5 w-5 text-white" />
      </div>

      <div className="ml-sm h-full w-full rounded-md bg-braytron-lightGray px-3 py-2 text-sm">
        <p>{message}</p>
      </div>
    </div>
  );
};

export { AIChatMessage };
